import ApiHelper from '~/helpers/ApiHelper';

const apiHelper = new ApiHelper();

export default class ProductService {
	static VEGETABLE = 1;
	static CONSERVE = 2;
	static BREAD = 3;
	static OTHERS = 4;
	static BASKET = 5;
	static DRINK = 6;
	static FRUIT = 7;

	axios;
	fields = [
		'name',
		'stock',
		'product_type_id',
		'members_only',
		'own_production',
		'posted_up',
		'descript',
		'prices_member',
		'prices_member_collaborator',
		'prices_not_member',
		'prices_shop',
		'basket_product_list',
		'remark',
	];

	constructor(axios) {
		this.axios = axios;
	}

	list(pagination, filters, order) {
		const params = apiHelper.setQueryParams(pagination, filters, order);
		return this.axios.get("/product", { params: params });
	}

	get(id) {
		return this.axios.get(`/product/${id}`);
	}

	getSelectorList(filters) {
		const params = apiHelper.setQueryParams(null, filters, {});
		return this.axios.get("/product/filter", { params: params });
	}

	store(product) {
		const data = apiHelper.buildParams(this.fields, product);
		return this.axios.post(`/admin/product`, data);
	}

	update(product) {
		const data = apiHelper.buildParams(this.fields, product);
		return this.axios.put(`/admin/product/${product.id}`, data);
	}

	massiveUpdate(ids, date_field, date) {
		const data = { ids: ids };
		data[date_field] = date;
		return this.axios.put(`/admin/product/massive/update`, data);
	}

	duplicateBasket(id) {
		return this.axios.post(`/admin/product/duplicate-basket/${id}`);
	}

	delete(id) {
		return this.axios.delete(`/admin/product/${id}`);
	}

	getPublicList(order) {
		const params = apiHelper.setQueryParams(null, {}, order);
		return this.axios.get(`/public/product`, { params: params });
	}
}
