//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppLayout from '../layouts/AppLayout';
import PublicProductsForm from '../components/forms/PublicProductsForm';
import UserService from '~/services/UserService';
import MyBalance from '~/components/core/MyBalance';
import NextDeliveryDate from '~/components/core/NextDeliveryDate';
import OrderService from '~/services/OrderService';
import ShopClose from '~/components/others/ShopClose.vue';
import moment from 'moment';
import ApiHelper from '~/helpers/ApiHelper';

export default {
	auth: false,
	components: { PublicProductsForm, AppLayout, MyBalance, NextDeliveryDate, ShopClose },
	data() {
		return {
			loaded: false,
			delivery_date: null,
			order_limit: null,
			apiHelper: new ApiHelper(),
			now: moment(),
			open: true,
			orderService: new OrderService(this.$axios),
		};
	},
	computed: {
		isMember() {
			return (
				this.$auth.$state.user &&
				this.$auth.$state.user.profile_id === UserService.CUSTOMER_PROFILE &&
				this.$auth.$state.user.customer_type !== UserService.NON_MEMBER &&
				this.$auth.$state.user.customer_type !== UserService.SHOP
			);
		},
	},
	mounted() {
		this.loaded = true;
		this.getShoppingInfo();
	},
	methods: {
		getShoppingInfo() {
			this.orderService
				.getShoppingInfo()
				.then((res) => {
					if (res.status === 200) {
						this.delivery_date = res.data.delivery_date;
						this.order_limit = res.data.order_limit;
						this.open = res.data.open;
					}
				})
				.catch((error) => {
					this.apiHelper.handleErrors(error, this.$refs.obs, this.$store);
				});
		},
	},
	head() {
		return {
			title: 'Tienda  | ' + process.env.NUXT_ENV_TITLE,
			meta: [
				{
					hid: 'description',
					name: 'description',
					content: process.env.NUXT_ENV_METADESCRIPTION,
				},
			],
		};
	},
};
