//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductService from '../../services/ProductService';
import ApiHelper from '../../helpers/ApiHelper';
import SpinButton from '../inputs/SpinButton';
import RegistrationBeforeOrderModal from '../modals/RegistrationBeforeOrderModal';
import PublicProductPriceSelect from '../inputs/PublicProductPriceSelect';

export default {
	name: 'PublicProductsForm',
	components: {
		PublicProductPriceSelect,
		RegistrationBeforeOrderModal,
		SpinButton,
	},

	data() {
		return {
			productService: new ProductService(this.$axios),
			apiHelper: new ApiHelper(),
			vegetable_type: ProductService.VEGETABLE,
			conserve_type: ProductService.CONSERVE,
			bread_type: ProductService.BREAD,
			others_type: ProductService.OTHERS,
			basket_type: ProductService.BASKET,
			drink_type: ProductService.DRINK,
			fruit_type: ProductService.FRUIT,
			tabs: [
				{ value: 0, text: '🧺 Cestas', type: ProductService.BASKET },
				{ value: 1, text: '🍅 Hortalizas', type: ProductService.VEGETABLE },
				{ value: 7, text: '🍉 Frutas', type: ProductService.FRUIT },
				{ value: 2, text: '🍯 Conservas', type: ProductService.CONSERVE },
				{ value: 3, text: '🥖 Pan', type: ProductService.BREAD },
				{ value: 4, text: '🍺 Bebidas', type: ProductService.DRINK },
				{ value: 5, text: '🍰 Otros', type: ProductService.OTHERS },
			],
			selected: 0,
			current_tab_products: [],
			products: [],
			vegetable_products: [],
			fruit_products: [],
			conserve_products: [],
			bread_products: [],
			others_products: [],
			drinks_products: [],
			basket_products: [],
			shopping_cart: {},
			type_list: this.basket_type,
			loading: true,
			cart_edited: false,
			total: null,
			order: { sortBy: 'name', sortDesc: false },
		};
	},

	watch: {
		shopping_cart: {
			handler(newValue) {
				if (this.$auth.$state.user) {
					const result = this.extractCurrentCart();
					if (result.products_quantity > 0 || this.cart_edited) {
						this.$store.commit('updateShoppingCartProducts', result);
					}
				}
			},
			deep: true,
		},
	},

	methods: {
		changeList(type) {
			this.type_list = type;
			if (this.type_list == this.basket_type) {
				this.current_tab_products = this.basket_products;
			} else if (this.type_list == this.vegetable_type) {
				this.current_tab_products = this.vegetable_products;
			} else if (this.type_list == this.conserve_type) {
				this.current_tab_products = this.conserve_products;
			} else if (this.type_list == this.bread_type) {
				this.current_tab_products = this.bread_products;
			} else if (this.type_list == this.drink_type) {
				this.current_tab_products = this.drinks_products;
			} else if (this.type_list == this.others_type) {
				this.current_tab_products = this.others_products;
			} else if (this.type_list == this.fruit_type) {
				this.current_tab_products = this.fruit_products;
			}
		},

		selectedTab(value) {
			for (const tab of this.tabs) {
				if (value == tab.value) {
					this.changeList(tab.type);
					break;
				}
			}
		},

		showRegistrationIfNotLogged() {
			if (!this.$auth.$state.user) {
				this.$refs.registrationBeforeModal.show();
			}
		},

		list() {
			this.loading = true;
			this.productService
				.getPublicList(this.order)
				.then((res) => {
					if (res.status === 200) {
						this.products = res.data.elements;
						this.total = res.data.total;
						this.loading = false;
						this.setByType();
					}
				})
				.catch((error) => {
					this.loading = false;
					this.apiHelper.handleErrors(error, this.$refs.obs, this.$store);
				});
		},

		setByType() {
			for (let product of this.products) {
				this.$set(this.shopping_cart, product.id, {
					name: product.name,
					product_type_id: product.product_type_id,
					own_production: product.own_production,
					price: null,
					quantity: 0,
				});
				if (product.product_type_id == this.vegetable_type) {
					this.vegetable_products.push(product);
				} else if (product.product_type_id == this.conserve_type) {
					this.conserve_products.push(product);
				} else if (product.product_type_id == this.bread_type) {
					this.bread_products.push(product);
				} else if (product.product_type_id == this.others_type) {
					this.others_products.push(product);
				} else if (product.product_type_id == this.basket_type) {
					this.basket_products.push(product);
				} else if (product.product_type_id == this.drink_type) {
					this.drinks_products.push(product);
				} else if (product.product_type_id == this.fruit_type) {
					this.fruit_products.push(product);
				}
			}
			this.current_tab_products = this.basket_products;
			this.setLocalStorageCart();
		},

		extractCurrentCart() {
			let current_cart = [];
			let current_products_quantity = 0;
			Object.keys(this.shopping_cart).forEach((product_id) => {
				if (this.shopping_cart[product_id].price && this.shopping_cart[product_id].quantity > 0) {
					this.cart_edited = true;
					let new_product = this.shopping_cart[product_id];
					new_product.product_id = product_id;
					current_cart.push(new_product);
					current_products_quantity++;
				}
			});
			return { current_cart: current_cart, products_quantity: current_products_quantity };
		},

		setLocalStorageCart() {
			if (process.client) {
				let storedCart = localStorage.getItem('shoppingCart');
				if (storedCart) {
					storedCart = JSON.parse(storedCart);
					for (let product of storedCart) {
						if (this.shopping_cart[product.product_id]) {
							this.$set(this.shopping_cart, product.product_id, {
								name: product.name,
								product_type_id: product.product_type_id,
								own_production: product.own_production,
								price: product.price,
								quantity: product.quantity,
							});
						}
					}
				}
			}
		},
	},
	mounted() {
		this.list();
	},
};
