import ApiHelper from '~/helpers/ApiHelper';

const apiHelper = new ApiHelper();

export default class OrderService {
	axios;
	fields = [
		'products',
		'address_id',
		'customer_id',
		'remark',
		'management_comment',
		'pay_with',
		'status',
		'delivery_date',
	];
	constructor(axios) {
		this.axios = axios;
	}

	summaryList(pagination, filters, order) {
		const params = apiHelper.setQueryParams(pagination, filters, order);
		return this.axios.get('/admin/summary/order-summary', { params: params });
	}

	mySummaryList(pagination, filters, order) {
		const params = apiHelper.setQueryParams(pagination, filters, order);
		return this.axios.get('/summary/order-summary', { params: params });
	}

	getShoppingInfo() {
		return this.axios.get(`/public/shopping-info`);
	}

	get(id) {
		return this.axios.get(`/order/${id}`);
	}

	list(pagination, filters, order) {
		const params = apiHelper.setQueryParams(pagination, filters, order);
		return this.axios.get('/order', { params: params });
	}

	store(order) {
		const data = apiHelper.buildParams(this.fields, order);
		return this.axios.post(`/order`, data);
	}
	adminStore(order) {
		const data = apiHelper.buildParams(this.fields, order);
		return this.axios.post(`/order/admin/store`, data);
	}
	update(order) {
		const data = apiHelper.buildParams(this.fields, order);
		return this.axios.put(`/order/${order.id}`, data);
	}

	reopen(order) {
		return this.axios.get(`/order/reopen-order/${order.id}`);
	}

	delete(id) {
		return this.axios.delete(`/order/${id}`);
	}

	pay(order_id, pay_with) {
		const data = { order_id: order_id, pay_with: pay_with };
		return this.axios.post(`/order/pay`, data);
	}

	exportExcel(start_date, end_date) {
		const params = new URLSearchParams();
		params.append('start_date', start_date);
		params.append('end_date', end_date);
		return this.axios.get('/admin/order/download/excel', {params: params, responseType: 'blob'});

	}
}
